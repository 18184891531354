<template>
  <div class="ui grid container-3" id="srvs">
    <div class="side-circle"></div>
    <div class="column">
      <vueper-slides slide-image-inside fade :infinite="true"
      :duration=3000
      :autoplay="true"
        fixed-height="100vh"
        width="100%"
        :slide-ratio="1 / 4"
        fixed-width="100%"
        class="no-shadow"
        :bullets="false"
        :visible-slides="1"
        >
        <vueper-slide fade
        v-for="(slide, i) in slides"
        :key="i" :image="slide.image"
        :title="slide.title"
        :content="slide.content">
        <template #content>
          <div v-if="i === 7" class="contents bank">
            <div class="details">
              <div class="vueperslide__title">Bank Transfer</div>
              <div class="vueperslide__content">
                Transfer funds online to other banks or e-wallets instantly via InstaPay.
              </div>
              <!-- <router-link class="yellow" @click.native="removehash" :to="{ path: '/instapay', hash: '#ciinsta'}" replace>
                LEARN MORE
              </router-link> -->
            </div>
          </div>

          <div v-if="(i !== 0 && i !== 7)" class="contents">
            <div class="details">
              <div class="vueperslide__title">{{ slide.title }}</div>
              <div class="vueperslide__content">{{ slide.content }}</div>
            </div>
          </div>

          <div v-if="i == 0" class="contents">
            <div class="details">
              <div class="vueperslide__title"><span>TayoCash</span> Features</div>
              <div class="vueperslide__content">
                Take a look at the many things you can do with TayoCash! <br>Click the arrow to learn more.
              </div>
            </div>
          </div>
        </template>

        </vueper-slide>
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'content3',
  data () {
    return {
      firstSlide: true,
      lastSlide: false,
      slides: [
        {
          title: '<span>TayoCash</span> Features',
          content: 'Take a look at the many things you can do with TayoCash! Click the arrow to learn more.',
          image: require('@/assets/h3d1.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Cash In, Cash Out',
          content: 'Add or withdraw money easily to your TayoCash wallet through our bank partners or other accredited partners and merchants.',
          image: require('@/assets/h3d2.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Buy Load',
          content: 'Buy or send prepaid telco load for calls, texts, and mobile data or load credits for your favorite video and mobile games.',
          image: require('@/assets/h3d3.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Pay Bills',
          content: 'Pay bills for utilities and other essentials conveniently to over 200 enrolled billers in the TayoCash app. Skip the lines and pay wherever you are.',
          image: require('@/assets/h3d4.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Pay QR',
          content: 'Pay our partner merchants directly using your TayoCash wallet through our Scan QR feature. Contactless and cashless for faster and safer transactions.',
          image: require('@/assets/h3d5.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Fund Transfer',
          content: 'Transfer money quickly and securely from your TayoCash wallet to other bank accounts or to other TayoCash members.',
          image: require('@/assets/h3d6.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Remittance',
          content: 'Send money effortlessly to your loved ones anywhere in the Philippines through our Remittance Partners in the TayoCash app.',
          image: require('@/assets/h3d7.png'),
          props: { autoplay: true, loop: true }
        },
        {
          title: 'Bank Transfer',
          content: 'Transfer funds online to other banks or e-wallets instantly via InstaPay.',
          image: require('@/assets/h3d8.png'),
          learnmore: true,
          props: { autoplay: true, loop: true },
          others: ''
        },
      ]
    }
  },
  components: {
    VueperSlides, VueperSlide
  },
  methods: {
    removehash() {
      this.$nextTick(() => {
        setTimeout(function() {
          window.location.hash = '_'
          history.replaceState(null, null, ' ');
        }, 50)
      })
    },
  }
}
</script>

<style scoped lang='scss'>
</style>
